import { Box, Button, ButtonGroup, Container, Grid, Typography } from '@mui/material';
import { width } from '@mui/system';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronCircleDown } from 'react-icons/fa';
import g1 from './game1.png';
import gamehead from './gamesHead.png';
import gamecat1 from './gamescat1.png';
import gamecat2 from './gamescat2.png';
import gamecat3 from './gamescat3.png';
import gamecat4 from './gamescat4.png';

import c2 from './c5.png';
import com1 from './Community1.png';
import com2 from './Community2.png';
import { MetaBlackBtn, MetaPageHeaderDetail, MetaPageHeaderText, MetaTextLabel } from './comp';




import {Carousel} from "react-responsive-carousel";


function GamesNewContainer() {
 

  return (
  

    <Box id="Team" sx={{
      backgroundColor: "#000000",
      paddingTop: 5
    }} >
      <Box id="MarketPlace" sx={{
      backgroundColor: "#000000",
      height: '100%',
      width:'100%',
      pt: 0,
      pb: {xs:0, sm:0}
    }}>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 0px 0px 0px', sm: '20px 0px 0px 0px'} }}
        >
          <Grid xs={12} md={12}>

          <Typography sx={{ color: '#FEDB24', fontSize: {xs:'25px', md: '32px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.11em',
            textTransform: 'uppercase'
          }}>
          METAFUSHKO RedefinES Gaming 
          </Typography>
          </Grid>
          <Grid item={'auto'} >
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron', px:"50px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', maxWidth: '800px'
                  }}>
                  It’s a revolutionary and rewarding collaboration of gaming and gambling. You get to play and win by staking and earning digital assets.
                  </Typography>
                    </Grid>
        </Grid>

    </Box>
    <Box id="Membership" sx={{
      backgroundColor: "#000000",
      height: '100%',
      pt: 0,
      pb: {xs:2, sm:3}
    }}>
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 12vw'},  }}
      >
        <Grid xs={12} md={6}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 30px', sm: '60px 30px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={gamecat1} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'5px', sm: '5px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  ENTER THE GRAND JACKPOT
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '14px'},fontFamily: 'Orbitron',  px:"50px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'5px', sm: '5px'},
                        textAlign: 'Center', maxWidth: '100%',
                  }}>
                  Our Grand Jackpot games combines all the money spent from players into a money prize for big rewards. Play the first game here for a chance to win the first jackpot prize!                   </Typography>
                    </Grid>
        
               </Grid>

            </Grid>
        </Grid>
        <Grid xs={12} md={6}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 30px', sm: '60px 30px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={gamecat2} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron', px:"50px",
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'5px', sm: '5px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  CRUSH RIVALS IN PVP Gulag 
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '14px'},fontFamily: 'Orbitron',  px:"50px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'5px', sm: '5px'},
                        textAlign: 'Center', maxWidth: '100%',

                  }}>
                  There’s nowhere to hide in this fast-paced, fast-win gaming battle where players can stake their digital assets in a player vs player gun game. The stakes are high, so don’t mess up! 
                  </Typography>
                    </Grid>
                   
               </Grid>

            </Grid>
        </Grid>
 

      </Grid>

      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 12vw'},  }}
      >
        <Grid xs={12} md={6}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 30px', sm: '60px 30px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={gamecat3} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
               </Grid>

               <Grid xs={12} md={12}>
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'5px', sm: '5px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                  Feeling Lucky? PLAY TO WIN 
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '14px'},fontFamily: 'Orbitron', px:"50px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'5px', sm: '5px'},
                        textAlign: 'Center', maxWidth: '100%',

                  }}>
                  Our epic selection of casino games offer both playability and rewards. Join in on the virtual fun and stand a chance to win digital assets which build your character and increase value.                                    </Typography>
                    </Grid>
        
               </Grid>

            </Grid>
        </Grid>
        <Grid xs={12} md={6}>
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'30px 30px', sm: '60px 30px'} }}
        >
               <Grid xs={12} md={12}>
               <img src={gamecat4} style={{ width: '100%', height: '100%', borderRadius: "25px" }} />
               </Grid>

               <Grid  xs={12} md={12}
                    >
               <Grid item={'auto'}>
                  <Typography sx={{ color: '#FEDB24', fontSize: {xs:'14px', sm: '20px'},fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                        mt: {xs:'5px', sm: '5px'},
                        textAlign: 'center', maxWidth: '100%',
                        textTransform: 'uppercase'

                  }}>
                 UNLOCK miniverse games
                  </Typography>
                    </Grid>
                    <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '14px'},fontFamily: 'Orbitron', px:"50px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'5px', sm: '5px'},
                        textAlign: 'Center',

                  }}>
                  One of the biggest benefits of joining your own exclusive miniverse is your access to unique games. It’s a first for metaversal games, and you’ll be one of the lucky few to be in from the start.                  </Typography>
                    </Grid>
                   
               </Grid>

            </Grid>
        </Grid>
 

      </Grid>


    </Box>
    </Box>
  );
}

export default GamesNewContainer;
