import { Box, Button, ButtonGroup, Container, Grid, Typography, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { width } from '@mui/system';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronCircleDown } from 'react-icons/fa';
import commAliens from './comAliens.png';
import Miniverse5 from './Miniverse5.png';
import travisImage from './TravisMarket.png';
import graph1 from './graph1.png';
import text1 from './text1.png';
import text2 from './text2.png';
import text3 from './text3.png';
import commHeader from './commHeader.png';
import { MetaBlackBtn, MetaPageHeaderDetail, MetaPageHeaderText, MetaTextLabel } from './comp';
import { yellow } from '@mui/material/colors';




import {Carousel} from "react-responsive-carousel";


function ComNewContainer() {

  const controlProps = (item) => ({
    checked: true,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });
 

  return (
  

    <Box id="Team" sx={{
      backgroundColor: "#000000",
      paddingTop: 0
    }} >
      <Box id="MarketPlace" sx={{
      backgroundColor: "#000000",
      height: '100%',
      width:'100%',
      pt: 0,
      pb: {xs:2, sm:3}
    }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 10vw'},  }}
      >
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 0px 30px 0px', sm: '0px 0px 60px 0px'} }}
        >
          <Grid xs={12} md={12}>

          <Typography sx={{ color: '#FEDB24', fontSize: {xs:'25px', md: '32px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.11em',
            textTransform: 'uppercase'
          }}>
          Voting Protocol
          </Typography>
          </Grid>
          <Grid item={'auto'}>
                  <Typography sx={{ color: '#FFF', fontSize: {xs:'14px', sm: '17px'},fontFamily: 'Orbitron', px:"250px",
                    fontStyle: 'normal',
                    fontWeight: 400,
                        mt: {xs:'15px', sm: '20px'},
                        textAlign: 'center', 
                  }}>
                    Everyone in the MetaFushko Metaverse plays a role in community decisions. You’ll get to vote on governance, design and gaming options for your community as well as propose new ideas for your community to vote on.
                    <br></br> 
                    <br></br>
                    Want a stronger vote? If you level up, you’ll strengthen your voting rights. 
                                        </Typography>
                    </Grid>
        </Grid>
        <Box  sx={{
              height: '100%',
              width:'100%',
              maxWidth:'1200px',
              border:1,
              borderRadius:'16px',
              borderColor:'#FEDB24'
                          
            }}>
        <Grid
        container
        direction="row"

        justifyContent="center"
        alignItems="center"
        maxWidth='1200px'
        height='100%'
        >
          <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="left"
            sx={{padding: {xs:'10px 20px', sm: '10px 30px'} }}

            >
                      <Grid item={'auto'}>
                      <Typography sx={{ color: '#FEDB24', fontSize: {xs:'17px', sm: '24px'},fontFamily: 'Orbitron',
                        fontStyle: 'normal',
                        fontWeight: 700,
                            mt: {xs:'15px', sm: '20px'},
                            textAlign: 'left', maxWidth: '100%'
                      }}>
                        VOTE ON:
                          </Typography>
                        </Grid>
                        <Grid xs={12} md={12}>
                          <Grid
                          container
                          direction="column"
                          justifyContent="start"
                          alignItems="left"
                          sx={{padding: {xs:'30px 0px', sm: '20px 0px'} }}
                          >
                          <img src={text1} style={{ width: '220px', height: '100%' }} />

                        <Typography sx={{paddingLeft:'35px', color: '#FFF', fontSize:  {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                          fontStyle: 'normal',
                          fontWeight: 400,
                              mt: {xs:'15px', sm: '20px'},
                              textAlign: 'left', maxWidth: '100%'
                        }}>
                          Vote on where to spend your community treasury funds 
                            </Typography>
                            
                            </Grid>                      
                        </Grid>

                        <Grid xs={12} md={12}>
                          <Grid
                          container
                          direction="column"
                          justifyContent="start"
                          alignItems="left"
                          sx={{padding: {xs:'30px 0px', sm: '20px 0px'} }}
                          >
                          <img src={text2} style={{ width: '133px', height: '100%' }} />

                        <Typography sx={{paddingLeft:'35px', color: '#FFF', fontSize:  {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                          fontStyle: 'normal',
                          fontWeight: 400,
                              mt: {xs:'15px', sm: '20px'},
                              textAlign: 'left', maxWidth: '100%'
                        }}>
                          Vote on the design of your miniverse and the special traits of your community
                            </Typography>
                            
                            </Grid>                      
                        </Grid>
                        <Grid xs={12} md={12}>
                          <Grid
                          container
                          direction="column"
                          justifyContent="start"
                          alignItems="left"
                          sx={{padding: {xs:'30px 0px', sm: '20px 0px'} }}
                          >
                          <img src={text3} style={{ width: '133px', height: '100%' }} />

                        <Typography sx={{paddingLeft:'35px', color: '#FFF', fontSize:  {xs:'14px', sm: '17px'},fontFamily: 'Orbitron',
                          fontStyle: 'normal',
                          fontWeight: 400,
                              mt: {xs:'15px', sm: '20px'},
                              textAlign: 'left', maxWidth: '100%'
                        }}>
                            Vote on types of games you’d like to have in your own exclusive miniverse 
                       </Typography>
                            
                            </Grid>                      
                        </Grid>
            </Grid>


        </Grid>
   

        </Box>

       
     


      </Grid>

      <Box id="Membership" sx={{
      backgroundColor: "#000000",
      height: '100%',
      pt: 8,
      pb: {xs:2, sm:3}
    }}>


      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{padding: {xs:'0px 5vw',sm:'0px 7vw',lg:'0px 12vw'},  }}
      >
      </Grid>
    </Box>
 </Box>
    </Box>

  );
}

export default ComNewContainer;
