import { Box, Button, ButtonGroup, Grid, TextField, Typography } from '@mui/material';
import part1 from './part1.png';
import part2 from './part2.png';
import part3 from './part3.png';
import part4 from './part4.png';
import part6 from './part6.png';
import part5 from './part5.png';
import progress from './Progress.png';
import { ReactComponent as YourSvg } from './progress.svg';
import { ReactComponent as YourSvgMobile } from './progressMobile.svg';






function ContactusContainer() {
  return (
    <>
    <Box sx={{
      backgroundColor: "#000000",
      height: '100%',
      padding: {xs:'10vw' ,md:'100px'}
    }}>
        <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{mt: 2, padding: '0px',mb: {xs:10,md:30}}}
      >
        <Grid xs={12} >
        <Typography sx={{ color: '#F9D649', fontSize: {xs:'24px' ,md:'30px'},fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.11em',
              textTransform: 'uppercase',
          }}>
         Development Timeline
          </Typography>
        </Grid>

          </Grid>
      

          <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{padding: {xs: "0px 0vw",sm:'0px '}, mb: 5 , display:{md: 'none'}}}
          >
                   <YourSvgMobile />

        </Grid>
      

          <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{mt: 25, padding: '0px', mb: 15, display:{xs:"none", md: 'inherit'}}}
          >
                         <YourSvg />

        </Grid>
    </Box>
    <Grid
    container
    direction="row"
    justifyContent="space-around"
    alignItems="center"
        sx={{ padding: '37px 8vw 90px', background: 'linear-gradient(270deg, #F9D649 -30.87%, #915700 100%)' }}
        rowGap={6}
  >
        <Grid xs={12}>
        <Typography sx={{ color: '#000000', fontSize: '30px',fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.5em',
              textTransform: 'uppercase',
            textAlign: 'center',
          }}>
          PARTNERS</Typography>
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={"auto"}>
        <img src={part6} />
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={"auto"}>
        <img src={part3} />
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={"auto"}>
        <img src={part5} />
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={"auto"}>
        <img src={part2} /> 
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={"auto"}>
        <img src={part4} />
          </Grid>
          <Grid xs={12} sm={6} md={3} lg={"auto"}>
          <img src={part1} />
          </Grid>
  </Grid>
    </>
  );
}



export default ContactusContainer;
